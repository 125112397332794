import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import GraphQLErrorList from '../components/graphql-error-list';
import Layout from '../containers/layout';
import Page from '../templates/page';

const Home = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const { site } = data || {};

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.',
    );
  }

  return <Page data={data} />;
};

Home.propTypes = {
  data: PropTypes.shape({
    bgImage: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }),
    }),
    bgImage2: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }),
    }),
    bgImage3: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }),
    }),
    bgImage4: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({}),
      }),
    }),
  }).isRequired,
  errors: PropTypes.shape({}),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

Home.defaultProps = {
  errors: null,
};

export const pageQuery = graphql`
  query {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    route: sanityRoute(id: { regex: "/(drafts.|)frontpage/" }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }
    bgImage: file(relativePath: { eq: "landing-page-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, maxHeight: 866) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage2: file(relativePath: { eq: "landing-page-bg-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, maxHeight: 866) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage3: file(relativePath: { eq: "landing-page-bg-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, maxHeight: 866) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage4: file(relativePath: { eq: "landing-page-bg-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, maxHeight: 866) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Home;
